export default [
  {
    path: "/:business",
    name: "Login",

    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/dashboard/:business",
    name: "Dashboard",

    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/stats/:business/:schedule_id",
    name: "Stats",

    component: () =>
      import(/* webpackChunkName: "stats" */ "@/views/Stats.vue"),
    meta: { requiresAuth: true },
  },
];
