<template>
  <div>
    <div class="hidden md:grid md:place-items-center text-white">
      <img
        src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
        class="w-1/6 pt-8"
      />
      <lottie-animation
        path="lotties/mobile-only.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        :height="420"
      />
      <p class="text-4xl text-center font-sans">Oops!</p>
      <p class="text-2xl text-center font-sans">
        This site is available only for Mobile Browsers
      </p>
    </div>
    <div class="block md:hidden">
      <Loader v-if="isPageLoading" />
      <router-view v-else :key="$route.fullPath" />
    </div>
  </div>
</template>
<script>
import Loader from "@/components/Elements/Loader.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    Loader,
    LottieAnimation,
  },
  mounted() {},
  computed: {
    ...mapGetters(["isPageLoading"]),
  },
};
</script>
<style scoped>
body,
html {
  background-color: #000000;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
