import axios from "axios";
import { config } from "@/common/app.config";
import TokenService from "../common/token.service";
import store from "@/store";
import CryptoJS from "crypto-js";
import { nanoid } from "nanoid";
import router from "@/router";
const ApiService = {
  init() {
    axios.defaults.baseURL = config.url;
    axios.interceptors.response.use(
      (response) => {
        if (response.config.method == "get") {
          if (
            !localStorage.getItem("ui_version") ||
            localStorage.getItem("ui_version") != response.data.ui_version
          ) {
            localStorage.setItem("ui_version", response.data.ui_version);
            router.go();
          }
        }

        if (response.data.errorcode && response.data.errorcode == 503) {
          return router.replace({ name: "Maintenance" });
        }
        return response;
      },
      function (error) {
        if (error) {
          switch (error.response.status) {
            case 401: {
              store.dispatch("signOutLocal");
              return router.replace({
                name: "Login",
                query: { redirect: router.options.history.state.back },
              });
            }
            case 500: {
              store.dispatch("setError", error.response.data.error);
              break;
            }
            case 503: {
              return router.replace({ name: "Maintenance" });
            }
          }
        }
        return Promise.reject(error);
      }
    );
  },

  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${TokenService.getToken("token")}`;
  },

  get(resource, params = {}, responseType = "") {
    return axios
      .get(`${resource}`, {
        params: params,
        responseType: responseType,
      })
      .catch((error) => {
        throw new Error(`ApiService ${error}`);
      });
  },

  simplePost(resource, params) {
    return axios.post(`${resource}`, params);
  },
  post(resource, params) {
    const jsonData = JSON.stringify(params);
    const encryptionKey = CryptoJS.enc.Utf8.parse(
      process.env.VUE_APP_ENCRYPTION_KEY
    );
    const encryptedData = CryptoJS.AES.encrypt(jsonData, encryptionKey, {
      mode: CryptoJS.mode.ECB,
    });
    const encryptedDataString = encryptedData.toString();
    let data = {
      xyb: this.splitStringBySequence(encryptedDataString),
      fck: this.splitStringBySequence(this.generateRandomEncryptedString()),
    };
    return axios.post(`${resource}`, data);
  },

  generateRandomEncryptedString() {
    const randomNumber = Math.floor(Math.random() * (80 - 60 + 1)) + 60;
    const randomString = nanoid(randomNumber);
    const secretKey = this.generateRandomAlphanumericString(randomNumber);
    const encryptedString = CryptoJS.AES.encrypt(
      randomString,
      secretKey
    ).toString();

    return encryptedString;
  },
  generateRandomAlphanumericString(length) {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charsetLength = charset.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charsetLength);
      result += charset.charAt(randomIndex);
    }

    return result;
  },
  splitStringBySequence(originalString) {
    const partLength = Math.ceil(originalString.length / 9);

    const parts = [];
    let startIndex = 0;

    for (let i = 0; i < 9; i++) {
      const endIndex = startIndex + partLength;
      parts.push(originalString.slice(startIndex, endIndex));
      startIndex = endIndex;
    }

    const indexString = process.env.VUE_APP_ENCRYPTION_ORDER;
    let shuffledArray = new Array();
    for (let i = 0; i < indexString.length; i++) {
      shuffledArray.splice(i, 0, parts[parseInt(indexString[i])]);
    }
    return shuffledArray;
  },
};

export default ApiService;
