import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import store from "@/store";
import TokenService from "@/common/token.service";
import BaseRoutes from "./BaseRoutes";

function getRoutes() {
  return [
    ...BaseRoutes,
    {
      path: "/maintenance",
      name: "Maintenance",
      component: () =>
        import(
          /* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"
        ),
    },
    {
      path: "/:catchAll(.*)*",
      name: "NotFound",
      component: NotFound,
    },
  ];
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: getRoutes(),
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.params.business != TokenService.getToken("business_slug")) {
      store.commit("purgeAuth");
      router.matcher = createRouter({
        history: createWebHistory(),
        routes: getRoutes(),
      }).matcher;
      location.reload();
    } else if (!store.getters.isAuthenticated) {
      next({ name: "Login", params: { business: to.params.business } });
    } else if (to.name === "Login") {
      next({ name: "Dashboard", params: { business: to.params.business } });
    } else {
      next();
    }
  } else {
    if (to.params.business) {
      try {
        const { data } = await ApiService.get(apiResource.checkBusinessSlug, {
          slug: to.params.business,
        });
        if (data.status) {
          if (to.params.business != TokenService.getToken("business_slug")) {
            store.commit("purgeAuth");
            router.matcher = createRouter({
              history: createWebHistory(),
              routes: getRoutes(),
            }).matcher;
            location.reload();
          }
          TokenService.saveToken("business_slug", data.data.slug);
          TokenService.saveToken("business_name", data.data.name);
          TokenService.saveToken("business_location", data.data.location);
          store.commit("setBusinessSlug", data.data.slug);
          store.commit("setBusinessName", data.data.name);
          store.commit("setBusinessLocation", data.data.location);
          if (store.getters.isAuthenticated) {
            next({
              name: "Dashboard",
              params: { business: to.params.business },
            });
          } else {
            next();
          }
        } else {
          next({
            name: "NotFound",
            params: { catchAll: to.params.business },
          });
        }
      } catch (error) {
        next({ name: "NotFound", params: { catchAll: to.params.business } });
      }
    } else {
      next();
    }
  }
});

export default router;
